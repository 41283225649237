import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { common } from '../utils/common';

class LogManager {
  analytics;

  sharedInstance() {
    if (this.analytics) return this.analytics;
    const firebaseConfig = process.env.NODE_ENV != 'development' ? common.proFirebase : common.devFirebase;
    const app = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(app);
    return this.analytics;
  }

  /// 执行log
  _logEvents(events, parameters) {
    parameters['debug_mode'] = true;
    logEvent(this.sharedInstance(), events, parameters);
  }
}
const logManager = new LogManager();
export const logFirebaseEvent = (eventString, parameters) => {
  try {
    logManager._logEvents(
      eventString,
      parameters ?? {},
    );
    // console.log('================== Firebase Event Start ==================');
    // console.log(eventString);
    // if (parameters != null) {
    //   console.log(parameters);
    // }
    // console.log('================== Firebase Event End ==================');
  } catch (e) {
    console.log(e);
  }
}